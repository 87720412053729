import React from "react"
import styled from "@emotion/styled"

import SEO from "../components/seo/seo"

import Layout from "../components/layout"

const ErrorContainer = styled.section`
  padding-top: 8rem;
  padding-bottom: 8rem;

  h1 {
    margin-bottom: 0.75rem;

    @media (min-width: 46rem) {
      grid-column-end: span 7;
    }
  }
`

const ErrorPage = () => {
  return (
    <Layout>
      <SEO
        title="Whoops!"
        description="¯\_(ツ)_/¯"
        image="1.jpg"
        pathname={`/404`}
        website
      />
      <ErrorContainer>
        <h1>Whoops!</h1>
        <p>¯\_(ツ)_/¯</p>

        <img src={"/gif/shrug.gif"} alt="Shrugs" />
      </ErrorContainer>
    </Layout>
  )
}

export default ErrorPage
